import React from "react"
import GetGGFXImage from "../common/site/get-ggfx-image"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import { Link } from "gatsby"
import "./TeamCard.scss"

const TeamCard = ({ item, processedImages, showGatsbyImage, showGif }) => {
  let Img = <></>

  if (showGif) {
    Img = <img src={item.Tile_GIF.url} alt="" />
  } else if (showGatsbyImage) {
    Img = (
      <GetGatsbyImage
        image={item.Image}
        fallbackalt={item.Name + " - " + item.Designation}
      />
    )
  } else {
    Img = (
      <GetGGFXImage
        imagename={"teams.Image.sliderimg"}
        imagesources={item.Image}
        fallbackalt={item.Name + " - " + item.Designation}
        imagetransformresult={processedImages}
        id={item.id}
      />
    )
  }

  return (
    <div className="team-wrap ">
      <div className="team-img zoom">
        {/* {!ShowGatsbyImage ? <GetGGFXImage
          imagename={"teams.Image.sliderimg"}
          imagesources={item.Image}
          fallbackalt={item.Name + " - " + item.Designation}
          imagetransformresult={processedImages}
          id={item.id}
        /> : <GetGatsbyImage
        image={item.Image}
        fallbackalt={item.Name + " - " + item.Designation}
        />} */}
        <Link className="zoom" to={`/meet-the-team/${item.URL}/`}>{Img}</Link>

        {/* <img src={item.Image.url} alt="" /> */}
      </div>
      <div className="team-content">
        <Link to={`/meet-the-team/${item.URL}/`}>
          <h3 className="team-person-h3">
            {item.Name}
            <i className="icon-right"></i>
          </h3>
        </Link>

        <h4 className="team-person-designation-h4">{item.Designation}</h4>

        {/* <div className="contact-info">
          <i className="icon-call"></i>
          <a href={`tel:${item.Phone}`} className="tel">
            {item.Phone}
          </a>
        </div>
        <div className="contact-info">
          <i className="icon-whatsapp"></i>
          <a href={`https://wa.me/+971585313748?text=Hi ${item.Name.split(" ")[0]},`} target="_blank" className="tel">
            Click to WhatsApp
          </a>
        </div> */}
      </div>
    </div>
  )
}

export default TeamCard
