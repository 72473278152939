import React, { useState } from "react";
import GetGatsbyImage from "../common/site/get-gatsby-image";
import GenerateLink from "../common/site/GetLink"
import "./Careers.scss";
// import ScrollAnimation from 'react-animate-on-scroll';
const Career = (props) => {
  const [hovered, setHovered] = useState(false);
  const toggleHover = (event) => {
    setHovered(event);
  }

  return (
    <React.Fragment>
      <section className="career">
        <div className="career-wrapper">
          {props.items && props.items.map((item, index)=> (
          <div className="career-img img-zoom" id={hovered != false && item.id != hovered ? 'blurblocks' : ''}
          onMouseEnter={()=>setHovered(item.id)}
          onMouseLeave={()=>setHovered(false)}>
            <GetGatsbyImage image={item.Image_Block_Tile_Image} fallbackalt={item.Image_Block_Title} />
            {/* <img src={item.Image_Block_Tile_Image.url} alt={item.Image_Block_Title} /> */}
            <div className="about-content">
              <h3>{item.Image_Block_Title}</h3>
              <GenerateLink link={item.Image_Block_CTA && item.Image_Block_CTA.Primary_URL} class="btn">{item.Image_Block_CTA_Label}<i className="icon-right"></i></GenerateLink>              
            </div>
          </div>
          ))}
          
        </div>
      </section>
    </React.Fragment>
  );
};
export default Career;
