import React, { useState } from "react";
import PlayVideo from "../PlayVideo/PlayVideo";
import "./PropertyList.scss";
import GenerateLink from "../common/site/GetLink"
import GetGatsbyImage from "../common/site/get-gatsby-image";
// import ScrollAnimation from 'react-animate-on-scroll';
const PropertyList = (props) => {
  const propertyValue = props.propertyValue;
  const [isPlay, setIsPlay] = useState(false);

  const playVideoHandler = () => {
    setIsPlay(true);
  };
  return (
    <React.Fragment>
      
      <div className="property-list-wrapper ">
        <div className="property-img zoom">
          <picture>
            <GetGatsbyImage image={propertyValue.Grid_Thumbail_Image} fallbackalt={propertyValue.Grid_Thumbail_Title} />
          </picture>
          {propertyValue.video_url && <button className="video-wrap" onClick={playVideoHandler}>
            <i className="video-btn"></i>
          </button>}
          
        </div>
        <div className="property-info">
          <h2>{propertyValue.Grid_Thumbail_Title}</h2>
          <h3>{propertyValue.Grid_Thumbail_Text}</h3>          
          <GenerateLink link={propertyValue.Grid_Thumbail_Link && propertyValue.Grid_Thumbail_Link.Primary_URL} mayAnchor class="btn-valuation">{propertyValue.Grid_Thumbail_Link_Label}<i className="icon-right"></i></GenerateLink>
        </div>
      </div>
      <PlayVideo isOpen={isPlay} videoId="V8ksSGxo6no" stopPlay={setIsPlay} />
    </React.Fragment>
  );
};
export default PropertyList;
