import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Team01 from "../../images/team01.png";
import Team02 from "../../images/team02.png";
import Team001 from "../../images/team001.png";
import Team002 from "../../images/team002.png";
import Team003 from "../../images/team003.png";
import Team004 from "../../images/team004.png";
import Team005 from "../../images/team-desk05.png";
import Team006 from "../../images/team-desk06.png";
import { Link } from "gatsby";
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby";
import "./Team.scss";
import GetGGFXImage from "../common/site/get-ggfx-image";
import TeamCard from "./TeamCard";
// import ScrollAnimation from 'react-animate-on-scroll';
import { isMobile } from "react-device-detect";
const Team = (props) => {
  const [isPlay, setIsPlay] = useState(false);
  const data = useStaticQuery(graphql`
  query  {  
    glstrapi {
      teams(sort: "sort_order:ASC", limit:11) {
        id
        Name
        Designation
        URL
        Phone
        sort_order
        Image {
          url
          alternativeText
        }
        imagetransforms
      }
    }
  }
  `)

  var settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 2800,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
        },
      },
    ],
  };
  var TeamList = data.glstrapi.teams;

  if(isMobile) {
    TeamList = TeamList.slice(0, 20);
  }


  return (
    <React.Fragment>
      <section className="team">
        <Container>
          <Row>
            <Col md={12}>
              <div className="team-info">
                <div className="about-team">
                  <div className="team-content">
                    <h3>Meet the Team</h3>
                  </div>
                  <Link to="/about/meet-the-team/" className="btn d-none d-md-block">
                    <span className="meet-team-cta">
                    Meet the whole team
                    <i className="icon-right"></i>
                    </span>
                  </Link>
                </div>
                <p>
                Quality service from the team that cares.
                </p>
                <Link to="/about/meet-the-team/" className="btn d-md-none">
                  Meet the whole team
                  <i className="icon-right"></i>
                </Link>
              </div>

              <Slider {...settings}>
                {TeamList && TeamList.map((item, index) => {
                  let processedImages = JSON.stringify({});
                  if (item?.imagetransforms?.Image_Transforms) {
                   processedImages = item.imagetransforms.Image_Transforms;
                  }
                  return(
                  <TeamCard item={item} key={index} processedImages={processedImages}/>
                  )
                })}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Team;
