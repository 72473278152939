import React from "react"
import GetGGFXImage from "../common/site/get-ggfx-image"
import { Link } from "gatsby"
import "./TwoColumns.scss"

const TeamCard = ({ item, processedImages }) => {
  if (typeof item === "undefined") return null
  const phoneNo = item.Phone;
  const WhatsApp_No = phoneNo.replace(/[^\d]/g, '');

  return (
    <div className="team-wrap">
      <div className="team-img zoom">
        <Link to={`/meet-the-team/${item.URL}/`}>
          <GetGGFXImage
            imagename={"teams.Image.sliderimg"}
            imagesources={item.Image}
            fallbackalt={item.Name + " - " + item.Designation}
            imagetransformresult={processedImages}
            id={item.id}
          />
        </Link>
      </div>
      <div className="team-content">
        <Link to={`/meet-the-team/${item.URL}/`}>
          <p className="name">
            {item.Name}
            <i className="icon-right"></i>
          </p>
        </Link>
        <p className="designation">{item.Designation}</p>

        <div className="contact-info">
          <i className="icon-call"></i>
          <a href={`tel:${item.Phone}`} className="tel">
            {item.Phone}
          </a>
        </div>
        <div className="contact-info">
          <i className="icon-whatsapp"></i>
          <a
           href={`https://wa.me/+${WhatsApp_No}?text=Hi ${item.Name.split(" ")[0]}, I came across McCone Properties’ website and wanted to reach out to you`} target="_blank"
            className="tel"
          >
            Click to WhatsApp
          </a>
        </div>
      </div>
    </div>
  )
}

export default TeamCard
