import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
// import { AnimationOnScroll } from "react-animation-on-scroll";
import parse from "html-react-parser"
import { Helmet } from "react-helmet";

import "./Faq.scss";

const Faq = ({faqs, noHeading}) => {
  var faqschemaList = [];
  
      faqs?.map((faqitem,i) =>{
        faqschemaList.push({
            "@type": "Question",
              "name": `${faqitem?.Title}`,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": `${faqitem?.Content}`
              }
        })
      })
  

    if (typeof faqs === "undefined") return null

  return (
    <section className="faq sampleee">
      <Helmet>
        <script type="application/ld+json">{`{
           "@context": "https://schema.org",
           "@type": "FAQPage",
           "mainEntity": ${JSON.stringify(faqschemaList, null, 2)}
        }`} 

        </script>
      </Helmet>
      <Container>
        <Row>
          <Col>
              {!noHeading && <h3>Frequently Asked Questions</h3>}
              <div className="questions-wrap">
                <Accordion>
                  {faqs?.map((question, index) => (
                    <Accordion.Item eventKey={index} key={question.id}>
                      <Accordion.Header>{question.Title}</Accordion.Header>
                      <Accordion.Body>{parse(question.Content)}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
};

export default Faq;
