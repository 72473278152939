import React from "react"
import "./TwoColumns.scss"
import parse from "html-react-parser"
import TeamCard from "./TeamCard"
// import { AnimationOnScroll } from "react-animation-on-scroll"

const TwoColumns = ({ module }) => {
  return (
    <div className="container">
      <div className="two-content">

          <div className="two-content-left">
            <div className="title">{parse(module.Left_Content)}</div>
          </div>
        <div className="two-content-right">
          <div className="content">{parse(module.Right_Content)}</div>
          {module.Select_Staffs && module.Select_Staffs.length > 0 &&
           <div className="team-info">
            <h4 className="title">Speak with one of our team</h4>
            <div className="team-info-content">
              <TeamCard
                item={module.Select_Staffs[0]}
                key={0}
                processedImages={module.Select_Staffs.processedImages}
              />
              { module.Select_Staffs[1] && 
                <TeamCard
                item={module.Select_Staffs[1]}
                key={1}
                processedImages={module.Select_Staffs.processedImages}
              />}
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default TwoColumns
