import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick"
import NewsCard from "../News/NewsCard"
// import { AnimationOnScroll } from "react-animation-on-scroll"
import "./NewsDetails.scss"
import { useStaticQuery, graphql } from "gatsby"
import { useWindowSize } from "../../hooks/window-size"

const NewsDetails = props => {
  const [windowWidth] = useWindowSize()

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        newsInsights(limit: 8, sort: "News_Date:DESC") {
          id
          Title
          URL
          Tile_Image {
            url
            alternativeText
          }
          Select_Category {
            Name
          }
          Reading_Time
          Above_Intro_Content
          Below_Intro_Content
          imagetransforms
        }
      }
    }
  `)

  var settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  var NewsList = data.glstrapi.newsInsights

  return (
    <React.Fragment>
      <section className="news-details">
        <Container>
          <Row>
            <Col md={12}>
              {/* <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInUp"
              > */}
                <div className="team-info">
                  <div className="about-team">
                    <div className="team-content">
                      <h3>News, Videos, and Insight</h3>
                    </div>
                    <Link
                      to="/about/latest-property-news-and-video/"
                      className="btn d-none d-md-block"
                    >
                      View More
                      <i className="icon-right"></i>
                    </Link>
                  </div>
                  <p>
                  Stay in the loop with the latest real estate trends, news and insights across Dubai and the rest of the world.
                  </p>
                </div> 
                  <Slider {...settings}>
                    {NewsList &&
                      NewsList.map((item, i) => {
                        let processedImages = JSON.stringify({})
                        if (item?.imagetransforms?.Tile_Image_Transforms) {
                          processedImages =
                            item.imagetransforms.Tile_Image_Transforms
                        }
                        return (
                          <NewsCard
                            key={item.id}
                            newsCard={item}
                            processedImages={processedImages}
                          />
                        )
                      })}
                  </Slider> 
              {/* </AnimationOnScroll> */}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default NewsDetails
