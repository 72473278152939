import React, {useEffect} from "react"
import "./FeedBackModal.scss"
import FeedbackForm from "../forms/feedback-form"
const FeedBackModal = ({isModalOpen, closeModal}) => {
    if (!isModalOpen) {
        return null
    }

    const onFormSubmit = (e) => {
        e.preventDefault()
        closeModal();
    }

  return (
    <div className="feedback-model-wrapper">
      <div className="feedback-model-container">
        <h3 className="title">Unlock your voice!</h3>
        <p className="description">
        Share your experience with us.
        </p>
        <div className="form-container">
          <FeedbackForm />
        
          <div className="close-btn" onClick={closeModal}>
            <i class="icon-close"></i>
          </div>
        </div>
      </div>
      <div className="modal-backdrop" onClick={closeModal}></div>
    </div>
  )
}

export default FeedBackModal
