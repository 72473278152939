import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import FeedBackModal from "../FeedBackModal/FeedBackModal"
import BtnImg from "../../images/btn-team-image.webp"
import "./Sticky.scss"
import $ from "jquery"
import { useLocation } from "@reach/router"

const ButtonGroup = () => {
  const [mobiledevice, setmobiledevice] = useState(false)
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false) 
  const [idleTime , setIdleTime ] = useState(0)
  const closeModal = () => setIsModalOpen(false)
  var userActivity = false;
  useEffect(() => {
    if (typeof window !== "undefined") {
      var mobiledevice = ""
      if ($(window).width() < 767) {
        setmobiledevice(true)
      }
    }  

    window.addEventListener("mousemove", () => { 
      userActivity = true; 
    })
    window.addEventListener("touchmove", () => {
      userActivity = true;
    })
    window.addEventListener("keypress", () => {
      userActivity = true;
    })  
    const interval = setInterval(() => {
      setIdleTime((idleTime) => {   
          if(userActivity && idleTime < 5){
            userActivity = false
            return 0
          }else{
            return idleTime + 1
          } 
      });  
    }, 60000);
    return () => clearInterval(interval);
  

  }, [])



  let msg =
    "Hi, I've come across the McCone Properties’ website and would like further information."

  if (typeof window !== "undefined") {
    const urlSections = window?.location?.href?.split("/")

    if (urlSections.length >= 4) {
      msg =
        urlSections[4] === "why-mccone-properties"
          ? "Hi, I've come across the Why McCone Properties page on McCone Properties’ website and would like further information."
          : urlSections[4] === "mccone-living"
            ? "Hi there, I came across the McCone Living page on McCone Properties’ website and would like further information"
            : urlSections[4] === "mortgage-services-in-dubai"
              ? "Hi there, I came across the Mortgage Service page on McCone Properties’ website and would like further information"
              : urlSections[4] === "conveyancing-services-in-dubai"
                ? "Hi there, I came across the Conveyancing Service page on McCone Properties’ website and would like further information"
                : urlSections[4] === "complaints-procedure"
                  ? "Hi there, I would like to file a complaint"
                  : urlSections[3] === "careers"
                    ? "Hi, I've come across the Careers page at McCone Properties’ website and would like further information."
                    : "Hi, I've come across the McCone Properties’ website and would like further information."
    }

    // if (urlSections.length >= 3) {
    //   msg =
    //     urlSections[3] === "careers"
    //       ? "Hi there, I came across the Careers page on McCone Properties’ website and would like further information"
    //       : "Hi, I've come across the McCone Properties’ website and would like further information."
    // }
  }

  let careersWhatspp;
  if (location.pathname.includes('careers')) {
    careersWhatspp = "+971585302663"
  }
  else {
    careersWhatspp = "+971585138750"
  }
  //console.log('idel time ', idleTime, userActivity)
  return (
    <>
      {idleTime >= 5 &&
        <div className="sticky-feedback-cta">
          <p>Submit your Feedback</p>
          <a href="javascript:void(0);" onClick={() => { setIsModalOpen(true) }}>Click Here</a>
        </div>
      }

      <div className="sticky-cta-section">

        <div className="contact-cta-fixed d-block d-md-none">
          <Link href="/contact/" className="btn">
            <i className="icon-envelope"></i>
            <span>
              Contact
            </span>
          </Link>
        </div>

        <section className="fixed-btn whatsapp-floating-btn">
          <Container>
            <Row>
              <Col md={12} className="">
                <div className="btn-groups">
                  <div className="btn-img">
                    <Link>
                      {/* <img src={BtnImg} /> */}
                      <img src={String(BtnImg)} style={{ "max-width": "82px !important" }} alt="WhatsApp - McConeGroup Pic" width={90} height={60} loading="lazy" />
                    </Link>
                  </div>
                  <Link
                    href={`https://wa.me/${careersWhatspp}?text=${msg}`}
                    target="_blank"
                    className="btn"
                  >
                    <i className="icon-whatsappnew d-block d-sm-none"></i>
                    <i className="icon-whatsapp d-none d-sm-block"></i>

                    <span className="d-md-none">WhatsApp</span>
                    <span className="d-none d-md-flex">Click to WhatsApp</span>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

      </div>
      <FeedBackModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </>
  )
}

export default ButtonGroup
